import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Checkbox,
  Select,
  Th,
  Td,
  TableContainer,
  Switch,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Grid,
  Flex,
  Input,
  useColorModeValue,
  useToast,
  FormLabel,
  FormControl,
  GridItem,
  Text,
  Spinner,
  Tooltip,
  HStack,
} from "@chakra-ui/react";
import {
  EditIcon,
  ViewIcon,
  DeleteIcon,
  ChevronRightIcon,
  SettingsIcon,
  AddIcon,
  Icon,
  ExternalLinkIcon,
} from "@chakra-ui/icons";
import { addContactManually, assignCSVContact, contactBulkImport, deleteAccount, getAllCampaignContactList, getCsvList, updateConactStatus } from "libs/addContactApi";
import { toast } from 'react-toastify';
import { FaRegTrashAlt } from "react-icons/fa";

interface NewContactType {
  campaignId: string;
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  websiteDomain: string,
  linkdedInUrl: string,
  [key: string]: string | boolean; // Adding index signature to support dynamic key access
}

interface Contact {
  _id: string;
  name: string;
  totalContact: number;
  isActive: boolean;
  isDeleted: boolean;
  createdDate: string;
}
interface EmailDetailsProps {
  setActiveStep: (step: number) => void;
  activeStep: number
}

const AddContacts: React.FC<EmailDetailsProps> = ({ setActiveStep, activeStep }) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgHover = useColorModeValue(
    { bg: "primary.100" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isExistingModalOpen, setIsExistingModalOpen] = useState(false);
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const toastT = useToast();
  const fileInputRef = useRef<HTMLInputElement | null>(null); // Create a ref for the file input
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingManual, setLoadingManual] = useState(false);
  const [loadingCSV, setLoadingCSV] = useState(false);

  const [csvData, setCsvData] = useState<Contact[]>([])
  const [csvCurrentPage, setCsvCurrentPage] = useState(1);
  const pageSize = 10;
  const totalPages = Math.ceil(csvData.length / pageSize);

  const [totalPageForContact, settotalPageForContact] = useState(1)

  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };


  const [newContact, setNewContact] = useState<NewContactType>({
    campaignId: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    websiteDomain: '',
    linkdedInUrl: '',
  });
  const [errors, setErrors] = useState({
    campaignId: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    websiteDomain: '',
    linkdedInUrl: '',
  });

  const clearContact = () => {
    setNewContact({
      campaignId: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      websiteDomain: '',
      linkdedInUrl: '',
    })
  }

  const [isCreateManualOpen, setIsCreateManualOpen] = useState(false); // New state for Create Manually modal

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setCsvFile(null); // Clear the file when closing the modal
  };

  const handleOpenCreateManualModal = () => {
    setIsOpen(false);
    setIsCreateManualOpen(true);

  };
  const handleCloseCreateManualModal = async () => {
    setIsExistingModalOpen(false);
    setIsCreateManualOpen(false);
    setErrors({
      campaignId: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      websiteDomain: '',
      linkdedInUrl: '',
    })
  };
  const handleSaveCreateManualModal = async () => {
    const newErrors: any = {};
    Object.keys(newContact).forEach((key) => {
      const error = validateField(key, newContact[key]);
      if (error) {
        newErrors[key] = error;
      }
    });
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setLoadingManual(true)
      let campId = sessionStorage.getItem('campaignId')
      const response = await addContactManually(newContact, campId)
      try {
        if (response.status == 200) {
          toast.success("Conact Added Successfully")
          setIsExistingModalOpen(false);
          setIsCreateManualOpen(false);
          fetchContactList()
          clearContact()
        } else if (response.status == 201) {
          toast.error(response.data.message)
        } else {
          toast.error(response.statusText)
        }
      } catch (error) {
      } finally {
        setLoadingManual(false)
      }
    }
  };


  const handleOpenExistingModal = async () => {
    const response = await getCsvList()
    try {
      if (response.status == 200) {
        setCsvData(response.data.csvList)
      } else {
        toast.error(response.statusText)
      }
    } catch (error) {

    }
    setCsvCurrentPage(1)
    setIsOpen(false); // Close the first modal
    setIsExistingModalOpen(true); // Open the existing contacts modal
  };


  const [selectedIds, setSelectedIds] = useState([]); // State to track selected IDs
  const [isAllSelected, setIsAllSelected] = useState(false); // State for master checkbox

  const handleCheck = (id: string) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        // If the ID is already selected, remove it from the array
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        // Otherwise, add the ID to the array
        return [...prevSelectedIds, id];
      }
    });
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      // If master checkbox is checked, uncheck all rows
      setSelectedIds([]);
      setIsAllSelected(false);
    } else {
      // If master checkbox is unchecked, select all rows
      const allIds = csvData.map((data) => data._id);
      setSelectedIds(allIds);
      setIsAllSelected(true);
    }
  };
  const isRowChecked = (id: string) => selectedIds.includes(id);
  const handleCloseExistingModal = async () => {
    setIsExistingModalOpen(false);
    setIsOpen(false);
  };
  const handleSaveExistingModal = async () => {
    if (selectedIds.length > 0) {
      setLoadingCSV(true)
      let campId = sessionStorage.getItem('campaignId')
      const response = await assignCSVContact(campId, selectedIds)
      try {
        if (response.status == 200) {
          toast.success(response.data.message)
          fetchContactList()
          setIsExistingModalOpen(false);
          setIsOpen(false);
          setSelectedIds([])
        } else {
          toast.error(response.statusText)
          setSelectedIds([])
        }
      } catch (error) {
      } finally {
        setLoadingCSV(false)
      }
    }
    else {
      toast.error("Please select file.")
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Use optional chaining in case files is null
    if (file && file.type === "text/csv") {
      setCsvFile(file);
      handleUploadCSV(file)
      // toastT({
      //   title: "File Selected",
      //   description: "CSV file has been selected.",
      //   status: "success",
      //   duration: 3000,
      //   isClosable: true,
      // });
    } else {
      toastT({
        title: "Invalid file type.",
        description: "Please upload a valid CSV file.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const triggerFileInput = async () => {
    if (fileInputRef.current) {
      let file = fileInputRef.current.click();
    }
  };

  const [uploadLoading, setUploadLoading] = useState(false)
  const handleUploadCSV = async (file: File) => {
    setUploadLoading(true)
    let campId = sessionStorage.getItem('campaignId')
    const response = await contactBulkImport(campId, file)
    try {
      if (response.status == 200) {
        fetchContactList()
        const reader = new FileReader();
        handleCloseModal();
        toast.success(response.data.message)
      } else {
        toast.error(response.data.message)
        fetchContactList()
      }
    } catch (error) {

    } finally {
      setUploadLoading(false)
    }
  }

  const validateField = (name: string, value: any): string => {
    let error = '';

    switch (name) {
      case 'firstName':
        if (!value) {
          error = 'Please enter first name';
        } else
          if (!/^[a-zA-Z\s]+$/.test(value)) {
            error = 'Please enter valid first name';
          }
        break;
      case 'lastName':
        if (!value) {
          error = 'Please enter last name';
        } else
          if (!/^[a-zA-Z\s]+$/.test(value)) {
            error = 'Please enter valid last name';
          }
        break;
      case 'email':
        if (!value) {
          error = 'Please enter email address.';
        } else
          if (!/^[\w.+-]+@([\w.-]+)[\w-]{2,4}$/.test(value)) {
            error = 'Please enter a valid email address.';
          }
        break;

      case 'phone':
        if (!value) {
          error = "Please enter phone number"
        } else if (value.length != 10) {
          error = 'Please enter a valid phone number.';
        }
        else
          if (!/^\d+$/.test(value)) {
            error = 'Please enter a valid phone number.';
          }
        break;

      default:
        error = '';
    }
    return error;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    const error = validateField(name, value);

    setNewContact({
      ...newContact,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: error,
    });
  };


  const [contactData, setContactData] = useState([])
  const fetchContactList = async () => {
    let campId = sessionStorage.getItem('campaignId')
    const response = await getAllCampaignContactList("", currentPage, campId)
    if (response.status == 200) {
      let dat = response.data.contactData.filter((data: { isDeleted: boolean }) => data.isDeleted !== true);
      setContactData(dat)
      settotalPageForContact(response.data.totalPages)
    } else {
      toast.error(response?.statusText || "Some error occurred");
    }
  }

  useEffect(() => {
    fetchContactList()
  }, [activeStep, currentPage])

  const handleDelete = async (id: string) => {
    let campId = sessionStorage.getItem('campaignId')
    const response = await deleteAccount(campId, id)
    try {
      if (response.status == 200) {
        toast.success(response.data.message)
        fetchContactList()
      } else {
        toast.error(response.statusText)
      }
    } catch (error) {

    }
  }

  const handleContactStatus = async (id: string, status: boolean) => {
    let campId = sessionStorage.getItem('campaignId')
    const response = await updateConactStatus(campId, id, status)
    try {
      if (response.status == 200) {
        fetchContactList()
      } else {
        toast.error(response.statusText)
      }
    } catch (error) {

    }
  }

  const handleNext = () => {
    setLoading(true)
    setTimeout(() => {
      window.scrollTo(0, 0);
      setActiveStep(3)
      setLoading(false)
    }, 1000);
  }
  const [campaignEdit, setCampaignEdit] = useState(false)
  useEffect(() => {
    const dat = sessionStorage?.getItem('campaignEdit')
    if (dat == "true") {
      setCampaignEdit(true)
    } else {
      setCampaignEdit(false)
    }
  }, [activeStep])
  const modalBodyRef = useRef(null);

  useEffect(() => {
    // Scroll to the top whenever the page changes
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [csvCurrentPage]); // Dependency on csvCurrentPage ensures the effect runs on page change

  return (
    <Box p={6} borderWidth={1} borderRadius="lg" boxShadow="sm">
      <IconButton
        onClick={handleOpenModal}
        colorScheme="blue"
        mb={4}
        display={"flex"}
        marginLeft={"auto"}
        marginBottom={5}
        icon={<SettingsIcon />}
        aria-label="Configure Email Account"
      />

      <TableContainer>
        <Table variant="simple">
          <Thead>
            {contactData.length > 0 &&
              <Tr>
                <Th fontSize={{ sm: '10px', lg: '12px' }} color='black'>Active</Th>
                <Th fontSize={{ sm: '10px', lg: '12px' }} color='black'>Contact Name</Th>
                <Th fontSize={{ sm: '10px', lg: '12px' }} color='black'>Email</Th>
                <Th fontSize={{ sm: '10px', lg: '12px' }} color='black'>Phone</Th>
                <Th fontSize={{ sm: '10px', lg: '12px' }} color='black'>Company</Th>
                {/* <Th fontSize={{ sm: '10px', lg: '12px' }} color='black'>Campaign Contact Status</Th> */}
                <Th fontSize={{ sm: '10px', lg: '12px' }} color='black'>Action</Th>
              </Tr>
            }
          </Thead>
          <Tbody>
            {contactData?.map((data) => {
              return (
                <Tr key={data.contactId} fontSize="sm" fontWeight="400" color='black'>
                  <Td>
                    <Switch
                      colorScheme="green"
                      isChecked={data.isActive}
                      onChange={() => handleContactStatus(data.contactId, !data.isActive)}
                    />
                  </Td>
                  <Td>{data?.firstName}</Td>
                  <Td>{data?.email}</Td>
                  <Td>{data?.phone}</Td>
                  <Td>{data?.websiteDomain}</Td>
                  {/* <Td>{data?.isContactActive ? 'Active' : 'Inactive'}</Td> */}
                  <Td>
                    {/* <IconButton aria-label="Edit" icon={<EditIcon />} mr={2} /> */}
                    <Flex>

                      {/* <Flex>
                        <Tooltip label="View" borderRadius={8} fontSize="sm">
                          <Button
                            alignItems="center"
                            justifyContent="center"
                            bg={bgButton}
                            _hover={bgHover}
                            _focus={bgFocus}
                            _active={bgFocus}
                            w="37px"
                            h="37px"
                            lineHeight="100%"
                            mx={2}
                            ms={0}
                            borderRadius="10px"
                          >
                            <Icon as={ViewIcon} color={iconColor} w="20px" h="20px" />
                          </Button>
                        </Tooltip>
                      </Flex> */}

                      <Flex>
                        <Tooltip label="Delete" borderRadius={8} fontSize="sm">
                          <Button
                            alignItems="center"
                            justifyContent="center"
                            bg={bgButton}
                            _hover={bgHover}
                            _focus={bgFocus}
                            _active={bgFocus}
                            w="37px"
                            h="37px"
                            lineHeight="100%"
                            mx={2}
                            ms={0}
                            borderRadius="10px"
                            onClick={() => handleDelete(data.contactId)}
                          >
                            <Icon as={FaRegTrashAlt} color={iconColor} w="20px" h="20px" />
                          </Button>
                        </Tooltip>
                      </Flex>
                    </Flex>

                    {/* <IconButton aria-label="View" icon={<ViewIcon />} mr={2} />
                    <IconButton aria-label="Delete" onClick={() => handleDelete(data.contactId)} icon={<DeleteIcon />} /> */}
                  </Td>
                </Tr>
              );
            })}

          </Tbody>
        </Table>
      </TableContainer>

      {contactData.length > 0 &&
        <HStack spacing={4} mt={4} justifyContent='center'>
          <Button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          {[...Array(totalPageForContact).keys()].map(page => (
            <Button
              key={page}
              onClick={() => handlePageChange(page + 1)}
              colorScheme={currentPage === page + 1 ? 'blue' : 'gray'}
            >
              {page + 1}
            </Button>
          ))}
          <Button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPageForContact}
          >
            Next
          </Button>
        </HStack>
      }

      {contactData.length > 0 &&
        <Button
          display={"flex"}
          justifyContent="flex-end"
          marginLeft="auto"
          width="auto"
          colorScheme="blue"
          mt={4}
          rightIcon={<ChevronRightIcon />}
          onClick={() => handleNext()}
        >
          {loading ? (
            <Flex alignItems='center'>
              <Text mr='2' >{campaignEdit ? 'Update & Next' : 'Save & Next'} </Text>
              <Spinner size='sm' mr='2' />
            </Flex>
          ) : (
            <>
              {campaignEdit ? 'Update & Next' : 'Save & Next'}
            </>
          )}
        </Button>
      }
      {contactData.length == 0 &&
        <p style={{ textAlign: "center" }}>No data found.</p>
      }

      {/* Configure Contacts Modal */}
      <Modal isOpen={isOpen} onClose={handleCloseModal} isCentered size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Configure Contacts</ModalHeader>
          <ModalCloseButton />
          <ModalBody pt={5}>
            {/* Grid layout for options */}
            <Grid templateColumns="repeat(3, 1fr)" gap={4}>
              {/* First Option: Import from CSV */}
              <Box p={4} borderWidth={1} borderRadius="lg">
                <Flex direction="column" align="center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="60"
                    height="60"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="icon icon-tabler icons-tabler-outline icon-tabler-file-type-csv"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                    <path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4" />
                    <path d="M7 16.5a1.5 1.5 0 0 0 -3 0v3a1.5 1.5 0 0 0 3 0" />
                    <path d="M10 20.25c0 .414 .336 .75 .75 .75h1.25a1 1 0 0 0 1 -1v-1a1 1 0 0 0 -1 -1h-1a1 1 0 0 1 -1 -1v-1a1 1 0 0 1 1 -1h1.25a.75 .75 0 0 1 .75 .75" />
                    <path d="M16 15l2 6l2 -6" />
                  </svg>
                  <Input
                    type="file"
                    accept="csv"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{ display: "none" }} // Hide the input
                  />
                  <Button
                    colorScheme="blue"
                    variant="solid"
                    onClick={triggerFileInput} // Open file dialog
                    my={4}

                  >
                    {uploadLoading ? (
                      <Flex alignItems='center'>
                        <Text mr='2' >Import from CSV </Text>
                        <Spinner size='sm' mr='2' />
                      </Flex>
                    ) : (
                      'Import from CSV'
                    )}

                  </Button>

                  {/* <Button
                    colorScheme="blue"
                    variant="solid"
                    onClick={handleUpload} // Handle upload
                  >
                 Import from CSV
                  </Button> */}
                  <Box fontSize="sm" textAlign="center">
                    Add contacts from CSV file to contact list via bulk upload.
                  </Box>
                </Flex>
              </Box>

              {/* Second Option: Add from Existing */}
              <Box p={4} borderWidth={1} borderRadius="lg">
                <Flex direction="column" align="center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="60"
                    height="60"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="icon icon-tabler icons-tabler-outline icon-tabler-users"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                  </svg>
                  <Button
                    colorScheme="blue"
                    variant="solid"
                    my={4}
                    onClick={handleOpenExistingModal}
                  >
                    Add from Existing
                  </Button>
                  <Box fontSize="sm" textAlign="center">
                    Add contacts from the contact list. Only contacts that are
                    not currently in your other campaigns can be added.
                  </Box>
                </Flex>
              </Box>

              {/* Third Option: Create Manually */}
              <Box p={4} borderWidth={1} borderRadius="lg">
                <Flex direction="column" align="center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="60"
                    height="60"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="icon icon-tabler icons-tabler-outline icon-tabler-user-plus"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                    <path d="M16 19h6" />
                    <path d="M19 16v6" />
                    <path d="M6 21v-2a4 4 0 0 1 4 -4h4" />
                  </svg>{" "}
                  <Button colorScheme="blue" variant="solid" my={4} onClick={handleOpenCreateManualModal}                  >
                    Create Manually
                  </Button>
                  <Box fontSize="sm" textAlign="center">
                    Add contacts to contact list manually one by one.
                  </Box>
                </Flex>
              </Box>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleCloseModal}>
              Save
            </Button>
            <Button variant="outline" borderColor={"#ccc"} onClick={handleCloseModal}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isExistingModalOpen}
        onClose={handleCloseExistingModal}
        size="3xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Contacts to Campaign</ModalHeader>
          <ModalCloseButton />
          <ModalBody ref={modalBodyRef}>
            <Box mb={4}>
              {/* Search Input */}
              {/* <Input
                placeholder="Search Contacts..."
                value={searchQuery} // Make sure to define `searchQuery` and its setter using useState
                onChange={(e) => setSearchQuery(e.target.value)} // Update state on change
              /> */}
            </Box>
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th fontSize={{ sm: '10px', lg: '12px' }} color='black'>
                      <Checkbox
                        isChecked={isAllSelected}
                        onChange={handleSelectAll} // Toggle all checkboxes
                      />
                    </Th>
                    <Th fontSize={{ sm: '10px', lg: '12px' }} color='black'>Name</Th>
                    <Th fontSize={{ sm: '10px', lg: '12px' }} color='black'>Total Contacts</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {csvData
                    .filter((data) =>
                      data.name.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                    .slice((csvCurrentPage - 1) * pageSize, csvCurrentPage * pageSize)
                    .map((csv) => (
                      <Tr key={csv._id}>
                        <Td>
                          <Checkbox
                            isChecked={isRowChecked(csv._id)} // Check individual row checkbox state
                            onChange={() => handleCheck(csv._id)} // Toggle individual checkbox
                          />
                        </Td>
                        <Td>{csv.name}</Td>
                        <Td>{csv.totalContact}</Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </TableContainer>

            {/* Pagination Controls */}
            {csvData.length > 0 &&
              <Flex mt={4} justifyContent="space-between" alignItems="center">
                <Button
                  onClick={() => setCsvCurrentPage((prev) => Math.max(prev - 1, 1))}
                  isDisabled={csvCurrentPage === 1}
                >
                  Previous
                </Button>

                {/* Page Buttons */}
                <Flex>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <Button
                      key={index + 1}
                      onClick={(e) => {
                        setCsvCurrentPage(index + 1);
                        // Scroll to top after state update
                        setTimeout(() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }, 0); // This small delay allows the state update to complete first
                      }}
                      colorScheme={csvCurrentPage === index + 1 ? "blue" : "gray"}
                      variant="solid"
                      mx={1}
                    >
                      {index + 1}
                    </Button>

                  ))}
                </Flex>

                <Button
                  onClick={() =>
                    setCsvCurrentPage((prev) => Math.min(prev + 1, totalPages))
                  }
                  isDisabled={csvCurrentPage === totalPages}
                >
                  Next
                </Button>
              </Flex>
            }
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handleSaveExistingModal}
            >
              {loadingCSV ? (
                <Flex alignItems='center'>
                  <Text mr='2' >Save </Text>
                  <Spinner size='sm' mr='2' />
                </Flex>
              ) : (
                'Save'
              )}
            </Button>
            <Button variant="outline" borderColor={"#ccc"} onClick={() => {
              handleCloseExistingModal();
              setIsOpen(true);
            }}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal size="3xl" isOpen={isCreateManualOpen} onClose={handleCloseCreateManualModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Contact Manually</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              <GridItem>
                <FormControl isInvalid={!!errors.firstName}>
                  <FormLabel>First Name</FormLabel>
                  <Input placeholder="First Name" name="firstName" value={newContact.firstName} onChange={handleInputChange} />
                  {errors.firstName && <Box color="red.500">{errors.firstName}</Box>}
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isInvalid={!!errors.lastName}>
                  <FormLabel>Last Name</FormLabel>
                  <Input placeholder="Last Name" name="lastName" value={newContact.lastName} onChange={handleInputChange} />
                  {errors.lastName && <Box color="red.500">{errors.lastName}</Box>}
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isInvalid={!!errors.email}>
                  <FormLabel>Email</FormLabel>
                  <Input placeholder="Email Address" name="email" value={newContact.email} onChange={handleInputChange} />
                  {errors.email && <Box color="red.500">{errors.email}</Box>}
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isInvalid={!!errors.phone}>
                  <FormLabel>Phone Number</FormLabel>
                  <Input placeholder="Phone Number" name="phone" maxLength={10} value={newContact.phone} onChange={handleInputChange} />
                  {errors.phone && <Box color="red.500">{errors.phone}</Box>}
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isInvalid={!!errors.linkdedInUrl}>
                  <FormLabel>LinkdIn URL</FormLabel>
                  <Input placeholder="LinkdIn URL" name="linkdedInUrl" value={newContact.linkdedInUrl} onChange={handleInputChange} />
                  {errors.linkdedInUrl && <Box color="red.500">{errors.linkdedInUrl}</Box>}
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isInvalid={!!errors.websiteDomain}>
                  <FormLabel>Website Domain</FormLabel>
                  <Input placeholder="Website Domain" name="websiteDomain" value={newContact.websiteDomain} onChange={handleInputChange} />
                  {errors.websiteDomain && <Box color="red.500">{errors.websiteDomain}</Box>}
                </FormControl>
              </GridItem>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveCreateManualModal}>
              {loadingManual ? (
                <Flex alignItems='center'>
                  <Text mr='2' >Save </Text>
                  <Spinner size='sm' mr='2' />
                </Flex>
              ) : (
                'Save'
              )}
            </Button>
            <Button variant="outline" borderColor={"#ccc"} onClick={() => {
              handleCloseCreateManualModal();
              setIsOpen(true);
            }}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box >
  );
}


export default AddContacts