import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  Text,
  Button,
  Icon,
  Tooltip,
  Spinner,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TableContainer,
  TabPanel,
  useColorModeValue,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  Switch,
  Input,
} from "@chakra-ui/react";
import { FaPlus, FaRegEdit, FaRegTrashAlt, FaHistory, FaBackward, FaArrowLeft } from "react-icons/fa";
import { getAllCampaigns } from "libs/apiFunctions";
import { campaignHistory, deleteCampaign } from "libs/campaignApi";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import DeleteConfirmationToast from "components/toast/DeleteConfirmationToast";

const HistoryTable = () => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgHover = useColorModeValue({ bg: "primary.100" }, { bg: "whiteAlpha.50" });
  const bgFocus = useColorModeValue({ bg: "secondaryGray.300" }, { bg: "whiteAlpha.100" });

  const [campaignsData, setCampaignsData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const history = useHistory();

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const fetchCampaignsHistory = async () => {
    setLoading(true);
    const id = await sessionStorage.getItem("campaignHistory")
    try {
      const response = await campaignHistory(id, searchText, currentPage, limit);
      if (response.status === 200) {
        setCampaignsData(response?.data?.mailHistory || []);
        setTotalPage(response.data.totalPages);
        setCurrentPage(response.data.currentPage)
      } else {
        setCampaignsData([]);
      }
    } catch (error) {
      setCampaignsData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCampaignsHistory();
  }, [currentPage, searchText]);

  return (
    <Box px="0px" my="0px">
      <Box borderRadius="16px" p={4} bgColor="#fff" overflow="hidden">
        <Tabs variant="brand">
          <TabList display='flex' justifyContent='flex-end'>
            <Box
              sx={{
                display: 'flex',
                ms: 'auto',
                pt: '10px',
              }}
            >
              <Input
                placeholder="Search..."
                borderRadius="30px"
                value={searchText}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearchText(e.target.value)
                }
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                ms: 'auto',
                pt: '10px',
              }}
            >
              <Button
                display={'flex'}
                me={'auto'}
                justifyContent={'flex-end'}
                variant="brand"
                color="white"
                borderRadius="30px"
                onClick={() => {
                  history.push("/admin/campaigns");
                }}
              >
                <Icon as={FaArrowLeft} color="white" mr="2" />
                Back to campaign
              </Button>
            </Box>


          </TabList>

          <TabPanels>
            <TabPanel>
              {loading && (
                <Flex justify="center" align="center" height="200px">
                  <Spinner size="lg" />
                </Flex>
              )}
              {!loading && campaignsData.length === 0 && (
                <Flex justify="center" align="center" height="200px">
                  <Text fontSize="lg" color="#222">
                    No Data Found
                  </Text>
                </Flex>
              )}
              {!loading && campaignsData.length > 0 && (
                <TableContainer mt={4}>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>S.NO.</Th>
                        <Th>Subject</Th>
                        <Th>Receiver Name</Th>
                        <Th>Receiver Email</Th>
                        <Th>Sender Name</Th>
                        <Th>Sender Email</Th>
                        <Th>Mail Sent Date</Th>

                      </Tr>
                    </Thead>
                    <Tbody>
                      {campaignsData.map((campaign, index) => (
                        <Tr key={campaign._id}>
                            <Td>{(currentPage - 1) * 10 + index + 1}</Td>
                          <Td>
                            <Tooltip label={campaign.mailSubject} aria-label="Full mail subject">
                              <span>{campaign.mailSubject.slice(0, 15) + "..."}</span>
                            </Tooltip>
                          </Td>
                          <Td>{campaign.contactFirstName + " " + campaign.contactLastName || "N/A"}</Td>
                          <Td>{campaign.contactEmail || "N/A"}</Td>
                          <Td>{campaign.senderName || "N/A"}</Td>
                          <Td>{campaign.senderEmail || "N/A"}</Td>
                          <Td>{campaign.mailSentDateTime.split("T")[0] || "N/A"}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
        {campaignsData.length > 0 && (
          <HStack mt={4} justifyContent="center">
            <Button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
              Previous
            </Button>
            {[...Array(totalPage).keys()].map((page) => (
              <Button
                key={page}
                onClick={() => handlePageChange(page + 1)}
                colorScheme={currentPage === page + 1 ? "blue" : "gray"}
              >
                {page + 1}
              </Button>
            ))}
            <Button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPage}>
              Next
            </Button>
          </HStack>
        )}
      </Box>
    </Box>
  );
};

export default HistoryTable;
